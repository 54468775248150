<template>
    <div :id="'project-measure-' + projectMeasure.id" class="measureslot filledmeasureslot" :class="[selectedClass, {'MeasureWidgetIneligible' : !projectMeasure.eligible}, {'MeasureWidgetHasInspectionChanges': hasInspectionAudits }]" @click="toggleMeasureSelection">
        <div class="measuredeletewrapper">
            <div class="measuredeletecolumnwrapper">
                <div class="measuredeletecolumn" v-if="delete_measures">
                    <div class="measuredeleteicon"
                         :class="{ 'measuredeleteiconinactive' : !isDeleting, 'measuredeleteiconactive' : isDeleting }"
                         @click="addMeasureToDelete">
                    </div>
                </div>
            </div>
            <div class="measuredetailstablediv">
                <table class="measuredetailstable">
                    <tbody>
                    <tr class="measureheaderrow">
                        <td class="measurenamecolumn">
                            <span v-if="projectMeasure.measure.incentivized === 1">Energy-Saving Improvement</span>
                            <span v-else>Additional Cost / Additional Opportunity</span>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td  v-if="edit_measures">&nbsp;</td>
                        <td class="hourscolumn">Settings</td>
                    </tr>
                    <tr class="measuredatarow">
                        <td class="measurenamecolumn measurenametr">
                            <span v-html="projectMeasure.measure.name"></span>
                        </td>
                        <td class="hourscolumn">
                            <i class="fa fa-eye" @click.stop="showProjectMeasureLastCalculatedWith" v-can="'project_measure_view_calculation_details'"></i>
                        </td>
                        <td class="hourscolumn">
                            <i :id="projectMeasure.id + '-complete-icon'" class="MeasureWidgetComplete fa fa-check" v-if="measureComplete"></i>
                            <i :id="projectMeasure.id + '-complete-icon'" class="MeasureWidgetIncomplete fa fa-exclamation-triangle" v-else title="This measure's details are not yet complete.  Please check its settings to fill in any missing information"></i>
                        </td>
                        <td class="hourscolumn m-4" v-if="edit_measures">
                            <div class="fa fa-clone MeasureDuplicateWidget"
                                 @click.stop="toggleDuplicateModal(projectMeasure.measure.id)" v-on:click.stop></div>
                        </td>
                        <td class="hourscolumn">
                            <i class="fa fa-gears MeasureDuplicateWidget cursor-pointer" @click.stop="showProjectMeasureDetailsForm" />
                        </td>
                    </tr>

                    <tr class="savingsdata" v-show="showProjectMeasureDetails">
                        <td class="littlenote">
                            <div v-if="projectMeasure.measure.incentivized">

                                    <span v-for="calculationResult in calculationResultDisplay">
                                        <div class="savingssubdiv" :class="{'adminqczero':calculationResult.flag}">{{ calculationResult.label}}:&nbsp;</div>
                                    <div class="savingssubdiv req" :class="{'adminqczero':calculationResult.flag}">{{ calculationResult.value }}</div> |
                                    </span>

                                <table>
                                    <tr v-if="hasBaselineWattage || hasReplacementWattage">
                                        <div class="savingssubdiv req" v-if="hasBaselineWattage">Baseline Wattage: {{ projectMeasure.baseline_wattage}} |&nbsp;</div>
                                        <div class="savingssubdiv req" v-if="hasReplacementWattage">Replacement Wattage: {{ projectMeasure.replacement_wattage}}</div>
                                    </tr>
                                </table>
                                <component
                                    v-for="(specialTextDetailsWidget, index) in specialTextDetailsWidgets"
                                    :key="specialTextDetailsWidget.value_field"
                                    :label="specialTextDetailsWidget.label"
                                    :value_field="specialTextDetailsWidget.value_field"
                                    :is="specialTextDetailsWidget.widget"
                                    :field-extra="specialTextDetailsWidget.field_extra"
                                    :project-measure="projectMeasure"
                                >
                                </component>
                                <!-- Watts Reduced measures Show Baseline and Proposed -->
                                <div class="savingssubdiv req" v-if="hasBaselineCount">Baseline Count: {{ getFieldValue(projectMeasure.generic_number_1) }} |&nbsp;</div>
                                <div class="savingssubdiv req" v-if="hasProposedCount">Proposed Count: {{ getFieldValue(projectMeasure.count) }} |&nbsp;</div>

                                <!-- Non Watts Reduced Measures, some measures will use the Generic Number field or the Count Field for display, but not both. -->
                                <div class="savingssubdiv req" v-if="isNonWattsReduced && projectMeasure.count != null && countLabel">{{ countLabel }}: {{ projectMeasure.count}} |&nbsp;</div>
                                <div class="savingssubdiv req" v-else-if="isNonWattsReduced && countLabel">{{ countLabel }}: TBD |&nbsp;</div>
                            </div>
                            <div v-else>
                                <div class="savingssubdiv" :class="getSavingsSubDivClass(projectMeasure.full_cost)">Total Full Cost:&nbsp;</div>
                                <div class="savingssubdiv req" :class="getSavingsSubDivClass(projectMeasure.full_cost)">{{ projectMeasure.full_cost | format_dollars }}</div> |
                            </div>
                            <div v-show="projectMeasureEquipment != 'TBD' || projectMeasure.measure.incentivized" class="savingssubdiv req" :class="getSavingsSubDivClass(projectMeasure.equipment_id)"> Equipment: {{ projectMeasureEquipment }}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <modal
                v-if="showDuplicateModal"
                :isConfirm="true"
                confirmText="Duplicate Measure"
                @close="showDuplicateModal = false"
                @confirmed="duplicateMeasure"
                id="duplicate-measure-confirm"
            >
                <span slot="header">Duplicate Measure</span>
                <div slot="body">

                    <form-select
                        field-name="room-list"
                        label="Select room"
                        :options="scenarioRooms"
                        v-model="selectedRoom"
                        option-label-field="room_name"
                        option-value-field="id"
                        :show-multiselect-labels="false"
                        :allow-empty="false"
                    ></form-select>

                    <p>Are you sure you want to duplicate this measure?</p>
                </div>
            </modal>
            <modal
                v-if="showCalculationDetailsModal"
                @close="showCalculationDetailsModal = false"
                modal-width="60%"
            >
                <template #header>
                    {{ projectMeasure.shared_uuid }}&nbsp;Calculation Details
                </template>
                <template #body>
                    <div v-show="!loadingCalculationDetails">
                        <div class="flex justify-around">
                            <div v-for="(methodDisplay, method) in internalCalculationResultDisplay" :key="method" class="flex flex-col w-1/3">
                                <div class="text-center text-bold text-md">{{ methodDisplay.label }}</div>
                                <div v-for="display in methodDisplay.displays" :key="display.key" class="flex justify-between">
                                    <div class="text-bold">{{ display.label }}</div>
                                    <span>{{ display.value }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-bold">Last Calculated With:</div>
                        </div>
                        <div ref="pm-last-calculated" class="mx-auto w-2/3"></div>
                    </div>
                    <div v-if="loadingCalculationDetails" class="text-center flex justify-center mb-4 relative">&nbsp;
                        <div class="loading-bar w-3/4 text-white p-12 rounded">
                            <span class="ml-4">Loading ...</span>&nbsp;
                        </div>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    import InspectionAudits from 'etap/mixins/measure_picker/inspection_audits';
    import domUtils from 'etap/mixins/dom-utils';
    import { format_decimals } from 'etap/filters/format_decimals'
    import { format_dollars } from 'etap/filters/format_dollars'
    import ProjectMeasure from 'etap/mixins/project_measure';
    import { mapState, mapActions } from 'vuex';
    import JSONFormatter from "json-formatter-js";

    export default {
        props: ['projectMeasure'],

        mixins: [MeasurePickerUtils, domUtils, InspectionAudits, ProjectMeasure],

        data() {
            return {
                showModal: false,
                showDuplicateModal: false,
                measureDuplicateComplete: false,
                selectedRoom: null,
                selectedMeasure: '',
                currentRoomMeasures: '',
                showCalculationDetailsModal: false,
                loadingCalculationDetails: false,
            }
        },

        created() {
            this.$store.dispatch('mp_technology/getTechnologyForMeasureAndProject', {
                measureId: this.projectMeasure.measure.id,
                project: this.project
            });
        },

        mounted() {
            $('#' + this.projectMeasure.id + '-complete-icon').uitooltip({});
        },

        computed: {
            project() {
                return this.$store.getters['mp_layout/getProject'](this.mpid);
            },
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            phase() {
                return this.$store.getters['mp_layout/getPhase'](this.mpid);
            },
            scenarioRooms() {
                return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.phase)
            },
            measureCalculationConfig() {
                return this.$store.state.mp_layout.measure_pickers[this.mpid].calculationConfiguration;
            },
            displaySharedUUid() {
                const sharedParts = this.projectMeasure.shared_uuid?.split('-');
                if(sharedParts) {
                    return sharedParts[sharedParts.length - 1]
                }
                return null;
            },
            calculationMethodologies() {
                let methodologies = [];
                this.measureCalculationConfig.calc_types.forEach(type => {
                    type.method.forEach(method => {
                        if(methodologies.includes(method)) {
                            return;
                        }
                        methodologies.push(method);
                    });
                });
                return methodologies;
            },
            internalCalculationResultDisplay() {
                let calcResultDisplay = {};
                this.calculationMethodologies.forEach(method => {
                    calcResultDisplay[method] = {
                        label: method.charAt(0).toUpperCase() + method.slice(1),
                        displays: []
                    };
                    this.measureCalculationConfig.calc_types.forEach(type => {
                        type.method.forEach(m => {
                            if(m !== method) {
                                return;
                            }
                            let pmAttribute = null;
                            let label = null;
                            if(m === 'actual') {
                                pmAttribute = type.type;
                                label = type.label;
                            }else {
                                pmAttribute = type.type + '_' + m;
                                label = type.label
                            }
                            let value = null;
                            if(type.is_money) {
                                value = format_dollars(this.projectMeasure[pmAttribute]);
                            }else if(typeof this.measureCalculationConfig.rounding[type.type] !== 'undefined') {
                                value = Number(this.projectMeasure[pmAttribute]).toFixed(this.measureCalculationConfig.rounding[type.type]);
                                value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add commas to large numbers
                            } else {
                                value = this.projectMeasure[pmAttribute];
                            }

                            calcResultDisplay[method].displays.push({
                                key: type.type + '_' + m,
                                label,
                                value
                            });
                        });
                    });
                });
                return calcResultDisplay;
            },
            calculationResultDisplay() {
                var calcResultDisplay = [];
                this.measureCalculationConfig.calc_types.forEach(type => {

                    if(!type.display) {
                        return;
                    }

                    type.method.forEach(method => {

                        var pmAttribute = null;
                        var label = null;
                        if(method === 'actual') {
                            pmAttribute = type.type;
                            label = type.label;
                        }else {
                            pmAttribute = type.type + '_' + method;
                            label = type.label + ' (' + method + ')'
                        }

                        // if the attribute is empty and we don't display if empty, return
                        if(!this.projectMeasure[pmAttribute] && !type.display_if_empty) {
                            return;
                        }

                        var value = null;
                        var flag = null;
                        if(type.is_money) {
                            value = format_dollars(this.projectMeasure[pmAttribute]);
                        }
                        else if(type.display_zero_if_negative && this.projectMeasure[pmAttribute] < 0) {
                            value = 0;
                            flag = false;
                        }
                        else {
                            value = Number(this.projectMeasure[pmAttribute]).toFixed(this.measureCalculationConfig.rounding[type.type]);
                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add commas to large numbers
                        }

                        if(flag !== null) {
                            // do nothing flag is set from a previous check
                        } else if(value !== null && typeof value === "string" && value.substr(0, 1) === '$') {
                            flag = false;
                        } else if ( (parseFloat(value) == 0 || isNaN(parseFloat(value)) ) &&
                            ( value !== "" || value == null) ){
                            flag = true;
                        }

                        calcResultDisplay.push({
                            label,
                            value,
                            flag
                        });
                    });
                });
                return calcResultDisplay;
            },
            ...mapState('mp_layout', [
                'editOnly'
            ]),
            formConfig() {
                return this.$store.getters['mp_layout/formConfigurationForProjectMeasure'](this.projectMeasure, this.project);
            },
            projectMeasureEquipment() {
                var equipmentName = 'TBD';
                if(this.measureEquipment && this.projectMeasure && this.projectMeasure.equipment_id) {
                    this.measureEquipment.forEach(equipment => {
                        if(equipment.id === this.projectMeasure.equipment_id) {
                            equipmentName = equipment.name;
                        }
                    });
                }
                return equipmentName;
            },
            measureEquipment() {
                return this.$store.getters['mp_equipment/measureEquipmentForMeasure'](this.projectMeasure.measure.id)
            },
            hasBaselineWattage() {
                var hasBaselineWattage = false;
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {
                        if(field.value_field === 'baseline_wattage') {
                            hasBaselineWattage = true;
                        }
                    });
                }
                return hasBaselineWattage;
            },
            hasReplacementWattage() {
                var hasReplacementWattage = false;
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {
                        if(field.value_field === 'replacement_wattage') {
                            hasReplacementWattage = true;
                        }
                    });
                }
                return hasReplacementWattage;
            },
            hasBaselineCount() {
                var hasBaselineCount = false;
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {
                        if(field.value_field === 'generic_number_1' && field.label === 'Baseline Count') {
                            hasBaselineCount = true;
                        }
                    });
                }
                return hasBaselineCount;
            },
            hasProposedCount() {
                var hasProposedCount = false;
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {
                        if(field.value_field === 'count' && field.label === 'Proposed Count') {
                            hasProposedCount = true;
                        }
                    });
                }
                return hasProposedCount;
            },
            isNonWattsReduced() {
                var isNonWattsReduced = false;
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {
                        if(field.value_field === 'count' && field.label != 'Proposed Count') {
                            isNonWattsReduced = true;
                        }
                    });
                }
                return isNonWattsReduced;
            },
            countLabel() {
                var countLabel = '';

                if(this.formConfig) {
                    var countFields = this.formConfig.fields.filter(field => {
                        if(field.value_field === 'count') {
                            return field;
                        }
                    });
                    if(countFields.length === 1) {
                        countLabel = countFields[0].label;
                    }

                }

                return countLabel
            },
            specialTextDetailsWidgets() {
                var specialTextDetailsWidgets = [];
                if(this.formConfig) {
                    this.formConfig.fields.forEach(field => {

                        if(!field.value_field) {
                            return;
                        }

                        if(field.value_field.search('special_text') !== -1) {
                            specialTextDetailsWidgets.push({
                                widget: field.widget + '-details',
                                value_field: field.value_field,
                                label: field.label,
                                field_extra: field.field_extra
                            });
                        }

                        if(field.value_field.search('generic_number_1')) {
                            specialTextDetailsWidgets.push({
                                value_field: field.value_field,
                                label: field.label,
                                field_extra: field.field_extra
                            });
                        }

                        if(field.label.search('Proposed Count')) {
                            specialTextDetailsWidgets.push({
                                value_field: field.label,
                                label: field.label,
                                field_extra: field.field_extra
                            });
                        }
                    })
                }
                return specialTextDetailsWidgets;
            },
            measureComplete() { // determine if the measure is deemed "complete"

                if(this.projectMeasure.completed === 1) {
                    return true;
                }

                return false;
            },
            selectedClass() {
                // only show classes if project measures are selectable based on initial config
                if(this.measuresSelectable) {
                    if(this.projectMeasure.selected == 1) {
                        return 'selectedmeasure'
                    }
                    return 'unselectedmeasure';
                }
                return '';
            },
            isDeleting() {
                var found = false;
                this.$store.getters['mp_project_measures/projectMeasuresToDelete'](this.mpid).forEach(m => {
                    if (this.projectMeasure.id == m.id) {
                        found = true;
                        return;
                    }
                });

                if (found) {
                    return true;
                }

                return false;
            },
        },

        filters: {
            format_decimals,
            format_dollars
        },

        methods: {
            async showProjectMeasureLastCalculatedWith() {
                this.showCalculationDetailsModal = true;

                try {
                    this.loadingCalculationDetails = true;
                    await this.$store.dispatch('mp_project_measures/getProjectMeasureLastCalculatedWith', {
                        projectMeasureId: this.projectMeasure.id,
                        mpid: this.mpid
                    });

                    this.loadingCalculationDetails = false;

                    this.$nextTick(() => {
                        const paramsFormatter = new JSONFormatter(this.projectMeasure.last_calculated_with);
                        this.$refs['pm-last-calculated'].appendChild(paramsFormatter.render());
                    })
                }catch(err) {
                    window.notify.apiError(err);
                }
            },
            showProjectMeasureDetailsForm() {

                this.$store.commit('mp_layout/setCurrentProjectMeasure', {
                    projectMeasure: this.projectMeasure,
                    mpid: this.mpid
                });
                this.$store.dispatch('mp_layout/showProjectMeasureDetails', {
                    projectMeasure: this.projectMeasure,
                    mpid: this.mpid
                });
            },
            toggleDuplicateModal() {
                this.selectedRoom = this.projectMeasure.room_id;
                if(this['edit_measures']) {
                    this.showDuplicateModal = true;
                    this.measureDuplicateComplete = true;
                }
            },
            duplicateMeasure() {
                this.$store.dispatch('mp_project_measures/duplicateMeasure', {mpid: this.mpid, measureId: this.projectMeasure.id, roomId: this.selectedRoom});
                this.showDuplicateModal = false;
                window.location.reload();
            },
            addMeasureToDelete(){
                if(this.isDeleting) {
                    this.$store.commit('mp_project_measures/removeProjectMeasureToDelete', {projectMeasure: this.projectMeasure, mpid: this.mpid});
                }else {
                    this.$store.commit('mp_project_measures/addProjectMeasureToDelete', {projectMeasure: this.projectMeasure, mpid: this.mpid});
                }
            },
            toggleMeasureSelection(e) {

                // filter out elements that we do still want to be clickable but don't want to toggle selection
                if(this.selfOrParentHasClass(e.target, 'MeasureWidget') ||
                    this.selfOrParentHasClass(e.target, 'modal-mask') ||
                    this.selfOrParentHasClass(e.target, 'measuredeleteicon')) {
                    return;
                }

                // only toggle selection if measures are selectable based on initial config
                if(this.measuresSelectable && !this.isLocked) {
                    this.$store.dispatch('mp_project_measures/toggleMeasureSelection', {projectMeasure: this.projectMeasure, mpid: this.mpid});
                }
            },
            getFieldValue(value) {

                if(value != null) {
                    return value;
                }

                return '';
            },
        }
    }
</script>
<style>
    .MeasureWidgetIncomplete {
        color: #ed803e;
    }

    .MeasureWidgetIneligible {
        background-color: #ffffad;
    }
</style>
